import { StaticImage as Img } from 'gatsby-plugin-image';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Content from './style';

export default function ContentSectionOne() {
  return (
    <Content>
      <Container>
        <Content.InnerBlock className="border-bottom">
          <Row className="justify-content-center">
            {/* about-us Content */}
            <Col xs="12" className="col-lg-7 col-md-6 col-xs-10">
              <Content.Box>
                <Content.Subtitle as="h6" mb="40px" fontColor="#136de8">
                  Making an Impact
                </Content.Subtitle>
                <Content.Title as="h2" mb="30px" mbXL="50px">
                  Case Study
                </Content.Title>
                <Content.ImageOne mb="40px">
                  <Img
                    src="../../../assets/image/foster_family.png"
                    alt="About"
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                </Content.ImageOne>
              </Content.Box>
            </Col>
            {/*/ .about-us Content */}
            <Col xs="12" className="col-lg-4 offset-xl-1 col-md-6 col-xs-10">
              <Content.Subtitle as="h6" mb="30px" fontColor="#136de8">
                Our customer
              </Content.Subtitle>
              <Content.Text mb="40px" mbXL="60px">
                Oklahoma Foster Care was doing the best they could to help
                children entering their system. Unfortunately, they did not have
                an effective method of notifying parents that met a child’s
                needs.
              </Content.Text>
              <Content.ImageTwo mb="40px">
                <Img
                  src="../../../assets/image/ipad_small.png"
                  alt="About"
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </Content.ImageTwo>
              <Content.Text mb="30px" ptXL="30px">
                Using Actovos, they were able to reduce the time to find a
                suitable home willing to accept a child from 5+ hours to 42
                minutes.
              </Content.Text>
            </Col>
          </Row>
        </Content.InnerBlock>
      </Container>
    </Content>
  );
}
