import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { StaticImage as Img } from 'gatsby-plugin-image';
import CounterBlock from './Components/CounterBlock';
import About from './style';

export default function AboutSection({ ...rest }) {
  return (
    <About backgroundColor="#F3F4F6" {...rest}>
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col
            xs="12"
            className="col-xl-6 col-lg-7 col-md-10 order-2 order-xl-1"
          >
            <About.Box mrXL="50px" mt="0" mt="30px" mtXL="0" pb="40px" pbLG="0">
              <About.ContentTextBlock className="text-center text-xl-end">
                {/* <About.Subtitle as="h6" fontColor="#136de8">
                  Why Choose Us
                </About.Subtitle> */}
                <About.Title as="h2" pb="35px" mb="40px">
                  We help you keep your audience informed
                  <br className="d-none d-sm-block d-lg-none d-xxl-block" />{' '}
                  wherever they are.
                </About.Title>
              </About.ContentTextBlock>
              <CounterBlock mt="50px" />
            </About.Box>
          </Col>
          <Col
            xs="12"
            className="col-xl-6 col-lg-6 col-md-8 col-sm-9 order-1 order-xl-2"
          >
            <About.Image>
              <Img
                className="w-100"
                src="../../../assets/image/home-digital-agency/about-videos-img.png"
                alt="content"
                layout="fullWidth"
                placeholder="blurred"
              />
            </About.Image>
          </Col>
        </Row>
      </Container>
    </About>
  );
}
