import React from 'react';
import Counter from './style';

export default function CounterBlock({ ...rest }) {
  return (
    <Counter {...rest}>
      <Counter.Single>
        <Counter.Title as="h3">
          <span className="counter">98</span>%
        </Counter.Title>
        <Counter.Text>
          Open rate for
          <br className="d-none d-xs-block" /> SMS messages
        </Counter.Text>
      </Counter.Single>
      <Counter.Single>
        <Counter.Title as="h3">
          <span className="counter">60</span>%
        </Counter.Title>
        <Counter.Text>
          Of recipients open texts
          <br className="d-none d-xs-block" /> within 1-5 minutes
        </Counter.Text>
      </Counter.Single>
      <Counter.Single>
        <Counter.Title as="h3">
          <span className="counter">300</span>+
        </Counter.Title>
        <Counter.Text>
          Minutes a day checking
          <br className="d-none d-xs-block" /> emails
        </Counter.Text>
      </Counter.Single>
    </Counter>
  );
}
